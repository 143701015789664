//FILM types
export const GET_FILMS_TVSERIES = 'GET_FILMS_TVSERIES';
export const GET_FILMS_MOVIES = 'GET_FILMS_MOVIES';
export const GET_FILMS_DETAILS = 'GET_FILMS_DETAILS';
export const LOADING_TRUE = 'LOADING_TRUE';
export const GET_FILMS_ALL = 'GET_FILMS_ALL';
export const ERROR = 'ERROR';

//Login & Auth types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

//Payment
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_ERROR = 'GET_TRANSACTION_ERROR';

export const PAYMENT_UPDATE_SUCCES = 'PAYMENT_UPDATE_SUCCES';
export const PAYMENT_UPDATE_FAIL = 'PAYMENT_UPDATE_FAIL';
