import React from 'react';

const NotFound = () => {
	return (
		<div>
			<h1 style={{ color: 'white', paddingTop: '70px', textAlign: 'center' }}>
				Upss... Not Found Isikan URL yang benar
			</h1>
		</div>
	);
};

export default NotFound;
