export const styles = {
  customInput: {
    width: "100%",
    height: "50px",
    background: "rgba(210, 210, 210, 0.25)",
    border: "2px solid #d2d2d2",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "1rem",
    color: "white",
    fontSize: "1.2rem",
  },
  customInputTitle: {
    width: "100%",
    height: "50px",
    background: "rgba(210, 210, 210, 0.25)",
    border: "2px solid #d2d2d2",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "1rem",
    color: "white",
    fontSize: "1.2rem",
    width: "160%",
  },
  customInputFile: {
    width: "100%",
    height: "50px",
    background: "rgba(210, 210, 210, 0.25)",
    border: "2px solid #d2d2d2",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "1rem",
    color: "white",
    fontSize: "1.2rem",
    width: "40%",
    justifySelf: "right",
    display: "none",
  },

  satuLine: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
};
